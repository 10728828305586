import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Header = styled.div`
  position: relative;
  width: 100vw;
  max-height: 400px;
  margin: 0 auto 128px auto;
  z-index: -1;

  img {
    filter: brightness(0.6) contrast(0.76);
    max-height: 400px;
  }

  @media only screen and (max-width: 768px) {
    margin: 124px auto 24px auto;
  }
`

const TitleBox = styled.div`
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 4.5em;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      font-size: 3.3rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2.3rem;
    }
  }

  h1,
  p {
    color: white;
  }
`

const HomeLink = styled(Link)`
  text-decoration: none;

  :hover {
    color: white;
  }

  ::after {
    content: " | ";
    color: white;
  }
`

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -12px;
`

const Thumbnail = styled.div`
  display: inline-block;
  flex: 1 1 40%;
  margin: 12px;
  padding: 18px;
  border-radius: 12px;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.15);
  transition: transform 180ms;

  :hover {
    transform: scale(1.015);
  }

  h2 {
    text-transform: uppercase;
    font-size: 1.8rem;
    line-height: normal;
  }
`

const ThumbLink = styled(Link)`
  text-decoration: none;
  text-align: center;
`

const Excerpt = styled.p`
  ::after {
    content: "weiter >>";
    display: inline;
    color: var(--deep-red);
  }
`

const Image = styled.img`
  display: block;
  margin: auto;
  max-height: 250px;
  max-width: 100%;
`

export default ({ pageContext }) => (
  <div>
    <SEO
      title="Blog"
      keywords={['bodytunes', 'ems', 'ems-training', 'ems-studio', 'fitness', 'blog']}
    />
    {pageContext.headerImage && (
      <Header>
        <Img fluid={pageContext.headerImage.fluid} alt="Header Image" />
        <TitleBox>
          <h1>BLOG</h1>
          <p>
            <HomeLink to="/">Home</HomeLink>
            Blog
          </p>
        </TitleBox>
      </Header>
    )}

    <Layout>
      <PostContainer>
        {pageContext.blogPosts.content.map(post =>
          (post.data.target !== null ? (
            <Thumbnail>
              <ThumbLink to={`/blog/${post.data.target.fields.slug.en_US}`}>
                <Image
                  src={
                    post.data.target.fields.clickableImage.en_US.fields.file
                      .en_US.url
                  }
                  alt="Thumbnail"
                />
                <h2>{post.data.target.fields.title.en_US}</h2>
                <Excerpt>
                  {post.data.target.fields.excerpt.en_US.content[0].content.map(
                    item => (
                      <p>{item.value}</p>
                    )
                  )}
                </Excerpt>
              </ThumbLink>
            </Thumbnail>
          ) : null)
        )}
      </PostContainer>
    </Layout>
  </div>
)
